import CafereoOrderStatuses from "../../consts/orderStatus/CafereoOrderStatuses";
import VendorOrderStatuses from "../../consts/orderStatus/VendorOrderStatuses";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import VendorProductStatus from "../../consts/productStatus/VendorProductStatus";
import ProductTypes from "../../consts/ProductTypes";
import OrderTypes from "../../consts/OrderTypes";
import ProductOrderTypes from "../../consts/ProductOrderTypes";
import { IncludeFilter } from "../../models/ag-grid/columnTypes";

// カフェレオ受注一覧カラム
const Cafereo = [
  { headerName: "受注ID", field: "orderId", pinned: "left" },
  { headerName: "指示書NO", field: "directionNumber" },
  {
    headerName: "受注区分",
    field: "orderType",
    filter: "dpSelectionFilter",
    filterParams: { options: OrderTypes.all() },
    valueGetter: (params) => OrderTypes.of(params.data.orderType),
  },
  {
    headerName: "区分",
    field: "productOrderType",
    filter: "dpSelectionFilter",
    filterParams: { options: ProductOrderTypes.all() },
    valueGetter: (params) => ProductOrderTypes.of(params.data.productOrderType),
  },
  {
    headerName: "商品区分",
    field: "productType",
    filter: "dpSelectionFilter",
    filterParams: { options: ProductTypes.all() },
    valueGetter: (params) => ProductTypes.of(params.data.productType),
  },
  {
    headerName: "受注ステータス",
    field: "orderStatus",
    filter: "dpSelectionFilter",
    filterParams: { options: CafereoOrderStatuses.all() },
    valueGetter: (params) => CafereoOrderStatuses.of(params.data.orderStatus),
  },
  {
    headerName: "商品ステータス",
    field: "productStatus",
    filter: "dpSelectionFilter",
    filterParams: { options: CafereoProductStatus.all() },
    valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
  },
  { headerName: "フォーキャスト受注", field: "forecastOrderFlg", type: "dpCheckmarkColumn" },
  { headerName: "登録日", field: "orderDate", type: "dpDateColumn" },
  { headerName: "社店CD", field: "customerCode" },
  { headerName: "法人名", field: "corporateName" },
  { headerName: "取引先名", field: "customerName", filterParams: IncludeFilter },
  { headerName: "JANCODE", field: "janCode" },
  {
    headerName: "タイトル",
    field: "title",
    filterParams: IncludeFilter,
  },
  {
    headerName: "商品名",
    field: "productName",
    filterParams: IncludeFilter,
  },
  { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
  { headerName: "発注締日", field: "orderCloseDate", type: "dpDateColumn" },
  { headerName: "発売日", field: "releaseDate" },
  {
    headerName: "Box入数",
    field: "inBoxQuantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "CT中Box入数",
    field: "inCtBoxQuantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "CT中pcs入数",
    field: "inCtPcsQuantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "CTsizeW",
    field: "ctSizeWidth",
    type: "dpNumericColumn",
  },
  {
    headerName: "CTsizeD",
    field: "ctSizeDepth",
    type: "dpNumericColumn",
  },
  {
    headerName: "CTsizeH",
    field: "ctSizeHeight",
    type: "dpNumericColumn",
  },
  { headerName: "入荷予定日", field: "arrivalPlanDate" },
  { headerName: "出荷予定日", field: "shipmentPlanDate", type: "dpDateColumn", filter: "agDateColumnFilter" },
  { headerName: "受注単位", field: "orderUnit", type: "dpNumericColumn" },
  { headerName: "受注数", field: "orderQuantity", type: "dpNumericColumn" },
  { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
  {
    headerName: "卸掛率",
    field: "wholesaleRate",
    type: "dpPercentColumn",
  },
  {
    headerName: "卸単価",
    field: "unitPrice",
    type: "dpNumericColumn",
  },
  { headerName: "単価合計金額", field: "unitPriceTotal", type: "dpNumericColumn" },
  { headerName: "メーカー名", field: "supplierName" },
  {
    headerName: "仕入掛率",
    field: "purchaseRate",
    type: "dpPercentColumn",
  },
  {
    headerName: "仕入単価",
    field: "purchasePrice",
    type: "dpNumericColumn",
  },
  { headerName: "粗利単価", field: "grossProfit", type: "dpNumericColumn" },
  { headerName: "粗利合計金額", field: "grossProfitTotal", type: "dpNumericColumn" },
  { headerName: "注残数", field: "backOrderQuantity", type: "dpNumericColumn" },
  { headerName: "配送CD", field: "deliveryCode" },
  { headerName: "配送先住所", field: "deliveryAddress" },
  { headerName: "届先法人名", field: "deliveryName1" },
  { headerName: "届先名称", field: "deliveryName2" },
  { headerName: "確保倉庫", field: "insureStorageName" },
  { headerName: "伝票番号", field: "slipNumber" },
  { headerName: "伝票備考", field: "slipRemarks" },
  { headerName: "親受注ID", field: "parentOrderId" },
  { headerName: "フォーキャスト割当分", field: "assignedForecastFlg", type: "dpCheckmarkColumn" },
  { headerName: "一括取込番号", field: "importNumber" },
  { headerName: "担当チーム", field: "salesTeam" },
  { headerName: "営業担当", field: "salesStaff" },
  { headerName: "アシスタント", field: "salesAssistant" },
  { headerName: "社内備考", field: "cafereoRemarks" },
  { headerName: "PO番号", field: "poNumber" },
  { headerName: "ハイコン", field: "hycon", type: "dpDateColumn" },
  { headerName: "取引先専用番号", field: "partnerPurchaseNo" },
  { headerName: "取引先専用明細番号", field: "partnerDetailNo" },
  { headerName: "法人CD", field: "corporateCode", hide: true },
  { headerName: "案件番号", field: "propositionNumber", hide: true },
  { headerName: "承認者", field: "approvalUser", hide: true },
  { headerName: "承認日時", field: "approvalDatetime", hide: true },
  { headerName: "掛率訂正承認者", field: "rateCorrectionAuthorizer", hide: true },
  { headerName: "掛率訂正承認日時", field: "rateCorrectionApprovalDate", hide: true },
  { headerName: "販売店承認者", field: "salesAuthorizer", hide: true },
  { headerName: "販売店承認日時", field: "salesApprovalDate", hide: true },
  { headerName: "納品予定日", field: "deliveryPlanDate", hide: true },
  { headerName: "伝票日付", field: "slipDate", hide: true },
  { headerName: "登録者", field: "createUser", hide: true },
  { headerName: "登録日時", field: "createDatetime", hide: true },
  { headerName: "更新者", field: "updateUser", hide: true },
  { headerName: "更新日時", field: "updateDatetime", hide: true },
  { headerName: "備考", field: "remarks", hide: true },
  { headerName: "発注ID", field: "purchaseId", hide: true },
];

// 販売店発注一覧カラム
const Vendor = [
  { headerName: "発注ID", field: "orderId", pinned: "left" },
  { headerName: "発注日", field: "orderDate", type: "dpDateColumn" },
  {
    headerName: "発注ステータス",
    field: "orderStatus",
    filter: "dpSelectionFilter",
    filterParams: { options: VendorOrderStatuses.all() },
    valueGetter: (params) => VendorOrderStatuses.of(params.data.orderStatus),
  },
  {
    headerName: "商品ステータス",
    field: "productStatus",
    filter: "dpSelectionFilter",
    filterParams: { options: VendorProductStatus.all() },
    valueGetter: (params) => VendorProductStatus.of(params.data.productStatus),
  },
  {
    headerName: "発注区分",
    field: "orderType",
    filter: "dpSelectionFilter",
    filterParams: { options: OrderTypes.all() },
    valueGetter: (params) => OrderTypes.of(params.data.orderType),
  },
  { headerName: "フォーキャスト", field: "forecastOrderFlg", type: "dpCheckmarkColumn" },
  { headerName: "発売元", field: "supplierName" },
  { headerName: "販売元", field: "makerName" },
  { headerName: "JANCODE", field: "janCode" },
  { headerName: "タイトル", field: "title" },
  { headerName: "商品名", field: "productName" },
  { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
  {
    headerName: "卸掛率",
    field: "wholesaleRate",
    type: "dpPercentColumn",
  },
  { headerName: "発注数", field: "orderQuantity", type: "dpNumericColumn" },
  {
    headerName: "卸単価",
    field: "unitPrice",
    type: "dpNumericDP2Column",
  },
  { headerName: "卸単価合計", field: "unitPriceTotal", type: "dpNumericColumn" },
  { headerName: "発売日", field: "releaseDate" },
  { headerName: "出荷予定日", field: "shipmentPlanDate", type: "dpDateColumn" },
  { headerName: "納品予定日", field: "deliveryPlanDate", type: "dpDateColumn" },
  { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
  { headerName: "届先法人名", field: "deliveryName1" },
  { headerName: "届先名称", field: "deliveryName2" },
  { headerName: "案件番号", field: "propositionNumber" },
  { headerName: "取引先専用番号", field: "partnerPurchaseNo" },
  { headerName: "取引先専用明細番号", field: "partnerDetailNo" },
];

export default { Cafereo, Vendor };
export { Cafereo, Vendor };
