import MakerPurchaseStatuses from "../purchaseStatus/MakerPurchaseStatuses";
import PurchaseTypes from "../PurchaseTypes";
import PurchaseProductTypes from "../PurchaseProductTypes";
import DeliveryCompanys from "../DeliveryCompanys";
import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";
import { AutocompleteSelectCellEditor } from "ag-grid-autocomplete-editor";
import "ag-grid-autocomplete-editor/dist/main.css";
import { IncludeFilter } from "../../models/ag-grid/columnTypes";
import ResponseTypes from "../ResponseTypes";

// カフェレオ発注一覧カラム
const Cafereo = [
  {
    headerName: "仕入先略称",
    field: "maker",
  },
  { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
  { headerName: "発売日", field: "releaseDate" },
  { headerName: "納期", field: "deliveryTime" },
  {
    headerName: "納品予約",
    field: "deliveryReservation",
    cellEditorFramework: "CustomDateCellEditor",
    valueFormatter: DateValueFormatter("YYYY/MM/DD"),
  },
  { headerName: "発注番号", field: "purchaseNo" },
  { headerName: "発注ID", field: "purchaseId" },
  {
    headerName: "発注区分",
    field: "purchaseType",
    filter: "dpSelectionFilter",
    filterParams: { options: PurchaseTypes.all() },
    valueGetter: (params) => PurchaseTypes.of(params.data.purchaseType),
  },
  { headerName: "発注締日", field: "orderClosingDate", type: "dpDateColumn" },
  { headerName: "JANCODE", field: "janCode" },
  {
    headerName: "タイトル",
    field: "title",
    filterParams: IncludeFilter,
  },
  {
    headerName: "商品名",
    field: "productName",
    filterParams: IncludeFilter,
  },
  { headerName: "発売元", field: "salesAgencyName" },
  { headerName: "販売元", field: "salesOriginName" },
  { headerName: "Box入数", field: "inBoxNumber", type: "dpNumericColumn" },
  { headerName: "注残数", field: "remainingQuantity", type: "dpNumericColumn" },
  { headerName: "発注数", field: "purchaseQuantity", type: "dpNumericColumn" },
  { headerName: "発注数(pcs)", field: "purchasePcsQuantity", type: "dpNumericColumn" },
  {
    headerName: "CR発注数",
    field: "originPurchaseQuantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "回答日",
    field: "responseDate",
    cellEditorFramework: "CustomDateCellEditor",
    valueFormatter: DateValueFormatter("YYYY/MM/DD"),
  },
  {
    headerName: "回答種別",
    field: "responseType",
    filterParams: { options: ResponseTypes.all() },
    valueGetter: (params) => ResponseTypes.of(params.data.responseType),
  },
  { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
  { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
  { headerName: "仕入単価", field: "unitPrice", type: "dpNumericColumn" },
  { headerName: "仕入合計金額", field: "purchasePriceTotal", type: "dpNumericColumn" },
  { headerName: "在庫数", field: "stockQuantity", type: "dpNumericColumn" },
  { headerName: "在庫金額", field: "stockPrice", type: "dpNumericColumn" },
  { headerName: "直送依頼", field: "directDeliveryRequest", type: "dpCheckmarkColumn" },
  { headerName: "直送可否", field: "directDeliveryLocalApproval", type: "dpCheckmarkColumn" },
  { headerName: "配送コード", field: "deliveryCode" },
  { headerName: "配送先郵便番号", field: "deliveryPostalCode" },
  { headerName: "配送先住所", field: "deliveryAddress" },
  { headerName: "届先法人名", field: "deliveryName1" },
  { headerName: "届先名称", field: "deliveryName2" },
  { headerName: "メーカー備考", field: "purchaseRemarks" },
  { headerName: "Cafereo備考", field: "cafereoRemarks" },
];

const CafereoEditable = [
  {
    headerName: "ステータス",
    field: "purchaseStatus",
    hide: true,
  },
  { headerName: "Box入数", field: "inBoxNumber", hide: true },
  { headerName: "単位", field: "unit", hide: true },
  { headerName: "発注ID", field: "purchaseId" },
  { headerName: "JANCODE", field: "janCode" },
  { headerName: "タイトル", field: "title" },
  { headerName: "商品名", field: "productName" },
  {
    headerName: "発注区分",
    field: "purchaseType",
    filter: "dpSelectionFilter",
    filterParams: { options: PurchaseTypes.all() },
    valueGetter: (params) => PurchaseTypes.of(params.data.purchaseType),
  },
  { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
  { headerName: "メーカー名", field: "maker" },
  {
    headerName: "発注数",
    field: "purchaseQuantity",
    type: "dpNumericColumn",
    editable: true,
    cellEditorFramework: "NumericCellEditor",
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updatePurchaseQuantity) {
        ret.backgroundColor = "#CCFFCC";
      }
      if (params.data.errorPurchaseQuantity) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
    // 利用先で定義
    // valueSetter: (params) => {
    // },
  },
  { headerName: "発注数(pcs)", field: "purchasePcsQuantity", type: "dpNumericColumn" },
  { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
  { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
  {
    headerName: "仕入価格",
    field: "unitPrice",
    type: "dpNumericColumn",
    editable: true,
    cellEditorFramework: "NumericCellEditor",
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateUnitPrice) {
        ret.backgroundColor = "#CCFFCC";
      }
      if (params.data.errorUnitPrice) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
    // 利用先で定義
    // valueSetter: (params) => {
    // },
  },
  { headerName: "仕入合計金額", field: "purchasePriceTotal", type: "dpNumericColumn" },
  {
    headerName: "納品予定日",
    field: "deliveryTime",
    type: "dpEditableColumn",
    cellEditorFramework: "AboutDateCellEditor",
    cellEditorParams: {
      cellEditorHideAbout: ["pending"],
    },
    valueFormatter: DateValueFormatter("YYYY/MM/DD"),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateDeliveryTime) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "納品予約",
    field: "deliveryReservation",
    type: "dpDateColumn",
    editable: true,
    cellEditorFramework: "CustomDateCellEditor",
    valueFormatter: DateValueFormatter("YYYY/MM/DD"),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateDeliveryReservation) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "配送業者",
    field: "deliveryCompany",
    editable: true,
    filter: "dpSelectionFilter",
    filterParams: { options: DeliveryCompanys.all() },
    //valueGetter: (params) => DeliveryCompanys.of(params.data.deliveryCompany),
    cellEditor: "agSelectCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      values: Object.keys(DeliveryCompanys.refDataArray()),
      cellEditorPopup: true,
    },
    refData: DeliveryCompanys.refDataArray(),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateDeliveryCompany) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "備考(メーカー表示)",
    field: "purchaseRemarks",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updatePurchaseRemarks) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "カフェレオ担当者", // 22
    field: "purchasePerson",
    editable: true,
    cellEditor: AutocompleteSelectCellEditor,
    filterValueGetter: (params) => {
      return params.data.purchasePerson.value
        ? String(params.data.purchasePerson.label)
        : String(params.data.purchasePersonName);
    },
    valueFormatter: (params) => {
      if (params.value.label) {
        return params.value.label;
      } else {
        return params.data.purchasePersonName;
      }
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updatePurchasePerson) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const valueALbel = valueA.value ? valueA.label : nodeA.data.purchasePersonName;
      const valueBLbel = valueB.value ? valueB.label : nodeB.data.purchasePersonName;
      if (valueALbel == valueBLbel) return 0;
      return valueALbel > valueBLbel ? 1 : -1;
    },
  },
  {
    headerName: "メーカー担当者",
    field: "makerPerson",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateMakerPerson) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "社内備考１",
    field: "cafereoRemarks",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateCafereoRemarks) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
];

// メーカー受注一覧カラム
const Maker = [
  { headerName: "発注番号", field: "purchaseNo" },
  {
    headerName: "発注区分",
    field: "purchaseType",
    filter: "dpSelectionFilter",
    filterParams: { options: PurchaseTypes.all() },
    valueGetter: (params) => PurchaseTypes.of(params.data.purchaseType),
  },
  {
    headerName: "発注商品区分",
    field: "purchaseProductType",
    filter: "dpSelectionFilter",
    filterParams: { options: PurchaseProductTypes.all() },
    valueGetter: (params) => PurchaseProductTypes.of(params.data.purchaseProductType),
  },
  { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
  { headerName: "発注締日", field: "orderClosingDate", type: "dpDateColumn" },
  { headerName: "納品日", field: "deliveryTime", cellStyle: { color: "red" } },
  {
    headerName: "納品日(確定)",
    field: "deliveryReservation",
    hide: true,
    type: "dpDateColumn",
  },
  { headerName: "JANCODE", field: "janCode" },
  { headerName: "タイトル", field: "title" },
  { headerName: "商品名", field: "productName" },
  { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
  { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
  { headerName: "発注数", field: "purchaseQuantity", type: "dpNumericColumn" },
  {
    headerName: "納品数",
    field: "deliveryQuantity",
    type: "dpNumericColumn",
    cellStyle: (params) => {
      let ret = {};
      if (params.data.deliveryQuantity != params.data.purchaseQuantity) {
        ret.color = "red";
      }
      return ret;
    },
  },
  { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
  { headerName: "販売単価", field: "unitPrice", type: "dpNumericColumn", hide: true },
  { headerName: "販売合計金額", field: "purchasePriceTotal", type: "dpNumericColumn", hide: true },
  { headerName: "備考", field: "remarks" },
  { headerName: "発注ID", field: "purchaseId", hide: true },
  {
    headerName: "ステータス（参考）",
    field: "purchaseStatus",
    filter: "dpSelectionFilter",
    filterParams: { options: MakerPurchaseStatuses.all() },
    valueGetter: (params) => MakerPurchaseStatuses.of(params.data.purchaseStatus),
    cellStyle: { backgroundColor: "#a9a9a9" },
  },
  { headerName: "配送先〒", field: "deliveryZipCode", hide: true },
  { headerName: "届先法人名", field: "delivery1", hide: true },
  { headerName: " 届先名称", field: "delivery2", hide: true },
  { headerName: "配送先住所", field: "deliveryAddress", hide: true },
  { headerName: "配送先TEL", field: "deliveryPhoneNumber", hide: true },
];

const MakerAnswerConfirm = [
  { headerName: "発注番号", field: "purchaseNo", pinned: "left" },
  { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
  { headerName: "JANCODE", field: "janCode" },
  { headerName: "タイトル", field: "title" },
  { headerName: "商品名", field: "productName" },
  { headerName: "受注数", field: "purchaseQuantity", type: "dpNumericColumn" },
  { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
  {
    headerName: "納品数",
    field: "deliveryQuantity",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateDeliveryQuantity) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "納品予定日",
    field: "deliveryTime",
    type: "dpEditableColumn",
    cellEditorFramework: "AboutDateCellEditor",
    cellEditorParams: {
      cellEditorHideAbout: ["pending"],
    },
    valueFormatter: DateValueFormatter("YYYY/MM/DD"),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateDeliveryTime) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "備考",
    field: "remarks",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateRemarks) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
];

const MakerReserveConfirm = [
  { headerName: "発注ID", field: "purchaseId", pinned: "left" },
  { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
  { headerName: "JANCODE", field: "janCode" },
  { headerName: "タイトル", field: "title" },
  { headerName: "商品名", field: "productName" },
  { headerName: "受注数", field: "purchaseQuantity", type: "dpNumericColumn" },
  { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
  { headerName: "納期", field: "deliveryTime" },
  {
    headerName: "納品予約",
    field: "deliveryReservation",
    type: "dpEditableColumn",
    cellEditorFramework: "CustomDateCellEditor",
    valueFormatter: DateValueFormatter("YYYY/MM/DD"),
  },
];

// メーカー発注一覧
const Maker1_5 = [
  { headerName: "発注番号", field: "purchaseNo" },
  { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
  {
    headerName: "納品日",
    field: "deliveryTime",
    cellStyle: () => {
      let ret = {};
      ret.backgroundColor = "#FFFFCC";
      return ret;
    },
  },

  {
    headerName: "納品日",
    field: "deliveryReservation",
    hide: true,
    type: "dpDateColumn",
    cellStyle: () => {
      let ret = {};
      ret.backgroundColor = "#FFFFCC";
      return ret;
    },
  },
  { headerName: "JANCODE", field: "janCode" },
  { headerName: "タイトル", field: "title" },
  { headerName: "商品名", field: "productName" },
  { headerName: "発売日", field: "releaseDate" },
  { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
  { headerName: "発注数", field: "originPurchaseQuantity", type: "dpNumericColumn" },
  {
    headerName: "納品数",
    field: "purchaseQuantity",
    type: "dpNumericColumn",
    cellStyle: (params) => {
      let ret = {};
      ret.backgroundColor = "#FFFFCC";
      if (params.data.originPurchaseQuantity != params.data.purchaseQuantity) {
        ret.color = "red";
      }
      return ret;
    },
  },
  { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
  { headerName: "販売単価", field: "unitPrice", type: "dpNumericColumn", hide: true },
  { headerName: "販売合計金額", field: "purchasePriceTotal", type: "dpNumericColumn", hide: true },
  { headerName: "備考", field: "remarks" },
  { headerName: "発注ID", field: "purchaseId", hide: true },
];

const MakerAnswerConfirm1_5 = [
  { headerName: "発注番号", field: "purchaseNo", pinned: "left" },
  { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
  { headerName: "JANCODE", field: "janCode" },
  { headerName: "タイトル", field: "title" },
  { headerName: "商品名", field: "productName" },
  { headerName: "発売日", field: "releaseDate" },
  { headerName: "発注数", field: "originPurchaseQuantity", type: "dpNumericColumn" },
  { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
  {
    headerName: "納品日",
    field: "deliveryTime",
    type: "dpEditableColumn",
    cellEditorFramework: "AboutDateCellEditor",
    cellEditorParams: {
      cellEditorHideAbout: ["pending"],
      cellEditorAddResponseType: true,
    },
    valueFormatter: DateValueFormatter("YYYY/MM/DD"),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateDeliveryTime) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "納品数",
    field: "purchaseQuantity",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updatePurchaseQuantity) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "分納",
    field: "installment",
    type: "dpEditableCheckmarkColumn",
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateInstallment) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
];

export default {
  Cafereo,
  CafereoEditable,
  Maker,
  MakerAnswerConfirm,
  MakerReserveConfirm,
  Maker1_5,
  MakerAnswerConfirm1_5,
};
export { Cafereo, CafereoEditable, Maker, MakerAnswerConfirm, MakerReserveConfirm, Maker1_5, MakerAnswerConfirm1_5 };
